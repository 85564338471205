.feedback-label {
  font-size: 12px;
  margin-right: 5px;
}

.star-holder {
  padding: 10px 0px;
}

.star {
  font-size: 20px;
  color: var(--ion-feedback-star);
  margin-right: 15px;
}

.web .star {
  cursor: pointer;
}

.star:last-child {
  margin: 0;
}

.box-holder-top {
  margin-top: 20px;
}

.separator {
  height: 1px;
  background-color: var(--ion-color-gray);
  margin: 0 5px;
}

div.textarea-wrapper {
  padding-inline-start: 5px;
}
.route-feedback .box-holder{
  border-radius: 0 !important;
}

/* .commentService {
  height: 50px;
} */

/* .commentTech {
  height: 160px;
} */

#main > ion-content.route-feedback > .ion-padding .box-holder .inline-input {
  border-bottom: 1px solid var(--ion-color-gray);
}
#main > ion-content.route-feedback > .ion-padding .box-holder .inline-input:last-child {
  border-bottom: 0;
}
#main > ion-content.route-feedback > .ion-padding .box-holder .inline-input {
  padding: 10px;
}
#main > ion-content.route-feedback > .ion-padding .inline-input label {
  flex: none;
  font-size: var(--ion-font-size);
  margin-inline-end: 50px;
  margin-top: 0;
}

#main > ion-content.route-feedback > .ion-padding .inline-input:first-child .feedback-label {
  margin: 0;
}
.alert-wrapper * {
  color: var(--ion-color-primary) !important;
}
.alert-wrapper {
  background-color: var(--okx-background-color) !important;
}


.alert-message.sc-ion-alert-ios {
  color: var(--ion-color-primary);
  font-family: var(--ion-default-font-thiner);
}

#main > ion-content.route-feedback > .ion-padding .mbsc-select-input.mbsc-control {
  border: 0;
}
ion-content.route-feedback .box-holder {
  padding: 0;
}
ion-content.route-feedback .box-holder.inline-input {
  padding: 10px;
}
ion-content.route-feedback .box-holder ion-item {
  --border-color: transparent;
}
ion-content.route-feedback .box-holder ion-list {
  padding: 0;
}
#main
  > ion-content.route-feedback
  > .ion-padding
  .select-picker-input--feedback
  + input.mbsc-select-input.mbsc-control {
  font-size: 12px;
  text-align: end;
  padding-right: 10px;
  background-image: url(../../assets/images/caret-down-black.svg) ;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

#main > ion-content.route-feedback > .ion-padding .top-medium {
  margin-top: 10px;
}

#main > ion-content.route-feedback > .ion-padding .top-medium .inline-input.inline-input--feedback {
  align-items: center;
  padding-top: 10px;
  border-bottom: 0;
}

.web ion-content.route-feedback {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web
  #main
  > ion-content.route-feedback
  > .ion-padding
  .top-medium
  .inline-input.inline-input--feedback {
  align-items: center;
  padding-top: 10px;
  border-bottom: 0;
}

.web #main > ion-content.route-feedback > .ion-padding {
  padding: 0 0 0 var(--ion-padding);
}
.web #main > ion-content.route-feedback > .ion-padding > div{
  overflow-y: auto  !important;
  padding:  var(--ion-padding) var(--ion-padding) var(--ion-padding) 0;
  height: 100%;
}




.web .route-feedback .ion-padding .frm-wrapper {
  overflow-y: auto;
}

.route-feedback .ion-padding .action-wrapper {
  left: 0;
  right: 0;
  bottom: 0;
}

.web .route-feedback .ion-padding ion-button:last-child {
  margin-bottom: 0;
}
.route-feedback ion-checkbox {
  margin: auto 20px;
}
.feedback-box-wrapper{
  padding: 10px;
  border: 1px solid var(--ion-color-gray);
}