.no-data-second-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-data-second-topic {
  min-width: 310px;
  height: auto;
  display: flex;
  justify-content: center;
}

.no-data-second-topic > img {
  object-fit: cover;
}
