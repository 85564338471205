ion-button.link.link-centered {
  margin: 0 auto;
}

.web ion-content.route-checkout {
  --background: transparent;
  --ion-color-white: transparent;
}

.paymentRequestButton {
  margin-top: 20px;
  padding: 0 2px;
}

.paymentRequestButtonCardAdd {
  margin-bottom: 20px;
  padding: 0 2px;
}

.link-lefted {
  width: 100%;
  text-align: left;
}

.pay-divider {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid var(--ion-color-primary); 
  line-height: 0.1em;
  margin: 30px 0 30px; 
} 

.pay-divider span { 
   background:var(--okx-background-color); 
   padding: 10px;
    line-height: 0;
}

.delivery-time-modal .modal-wrapper {
  height: 70%;
  width: 90%;
}

.web .delivery-time-modal .modal-wrapper {
  width: 500px;
}

.delivery-time-modal .mbsc-select-input.mbsc-control {
  display: none !important;
}
