.route-cards .ion-padding .absolute-content .link {
  font-size: 11px !important;
  /* font-weight: bold; */
  text-transform: initial;
}

.web ion-content.route-cards {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web .route-cards .ion-padding {

}

.web .route-cards .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.route-cards .card-wrapper ion-text, .route-cards .card-wrapper .small-text{
  display: block;
  font-weight: 600;
}
.route-cards .card-wrapper .single-item{
  margin-bottom: 5px;
}

