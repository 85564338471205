.table-number-input {
  --padding-start: 0;
  text-align: left;
  border-bottom: 1px solid;
  border-bottom-color: var(--ion-color-light);
  margin-left: 0;
}

#main > ion-content.route-order-to-table .select-picker-label,
#main > ion-content.route-order-to-table .time-picker-label {
  color: var(--ion-color-primary);
  font-size: 12px;
  font-family: primary-bold;
}

.click-collect-wrapper {
  margin: var(--ion-padding) 0;
  height: 100%;
}

.table-number ion-text {
  margin: auto auto;
}

.click-collect-button {
  position: absolute;
  bottom: 20px;
  width: calc(100vw - 40px);
  left: 20px;
}

.click-collect-layout {
  height: 100%;
}

.no-padding {
  height: 100%;
}
.click-collect-dialog-header {
  padding: 0 var(--ion-padding);
}
.web .click-collect-wrapper {
  margin: var(--ion-padding) 0;
  height: fit-content;
}

.web .click-collect-button {
  position: sticky;
  width: unset;
  margin: unset;
  left: 0;
}

.web .no-padding {
  height: fit-content;
}

.table-number {
  height: 100px;
  padding: var(--ion-padding);
}
.table-number .mbsc-select-input {
  border-bottom: 1px solid var(--ion-color-light);
}
.route-order-to-table .click-collect-dialog-content {
  padding: 0;
}
.shadowed-box-light {
  border-bottom: 1px solid var(--ion-color-light);
}

.web ion-content.route-order-to-table .ion-padding {
  overflow-y: auto;
}

.click-collect-button {
  background-color: transparent;
}

@media screen and (max-height: 730px) {
  .web ion-content.route-order-to-table .ion-padding {
    top: 15% !important;
  }
}
