.incrementer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.incrementer-decrease,
.incrementer-incease {
  flex: 0 0 var(--okx-incrementer-button-width);
  height: var(--okx-incrementer-button-width);
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 1px;
  --border-style: none;
  --background: transparent;
  font-size: 20px;

  --color: var(--ion-color-secondary);
  margin: 0;
}

.incrementer-quantity {
  flex: 1 1 auto;
  padding-left: var(--okx-incrementer-button-width);
  padding-right: var(--okx-incrementer-button-width);
}

.incrementer-quantity-solo {
  flex: 0 0 45px;
  text-align: center;
}

.incrementer-quantity-value {
  font-weight: bold;
}

.incrementer-note {
  font-size: var(--okx-small-text-font-size);
  color: var(--ion-color-gray);
}

.web .incrementer-decrease,
.web .incrementer-incease {
  --color: var(--ion-color-secondary);
}

.route-apply-points .incrementer{
  background: var(--ion-color-primary);
  height: 240px;
  width: 100%;
  position: absolute;
  left: 0;
  padding: 30px;
}

.route-apply-points .incrementer-decrease,
.route-apply-points .incrementer-incease{
  --background: var(--ion-color-secondary);
  color: white;
  border: 1px solid white;
}

.route-apply-points .incrementer-quantity-value,
.route-apply-points .incrementer-quantity-unit,
.route-apply-points .incrementer-note,
.route-apply-points .incrementer-content{
  color: var(--ion-color-tertiary) !important;
}