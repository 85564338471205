.validate-button-wrapper {
  height: 45px;
}

.validate-button-wrapper ion-row {
  width: 170px;
  min-width: fit-content;
  margin: 0 auto;
  border-radius: 20px;
  border: 1px solid var(--ion-color-gray);
  margin-bottom: 25px;
  color: var(--ion-color-gray);
}

.account-validate .validate-button-wrapper.validate-button-wrapper--gridy ion-row {
  display: flex;
  padding: 0 5px;
}
.validate-button-wrapper.validate-button-wrapper--gridy ion-row {
  display: flex;
  padding: 0 45px;
}

.validate-button-wrapper ion-row ion-col div ion-icon {
  font-size: 19px;
  color: var(--ion-color-gray);
}

.validate-button-wrapper ion-row ion-col.verified-right {
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  justify-self: flex-start;
  flex-grow: 3;
}
