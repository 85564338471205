.order-categories {
  flex: 0 0 auto;
  padding: 0;
}

.order-categories h2 {
  font-size: var(--okx-sectiontitle-font-size);
  padding: 0 25px;
  margin: 15px 0;
}

.order-categories-inner {
  display: flex;
  width: 100%;
  overflow-y: auto;
}
.order-categories-inner > ion-button{
  padding-bottom: 10px;
  height: 30px !important;
}
.order-content {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column;
  padding: 0 25px;
  overflow: hidden;
  margin-bottom: 50px;
}

.order-list-items > ion-list > div:last-child > div > div:last-child > ion-item {
  padding-bottom: 45px;
}

.category-button {
  --color: var(--ion-color-primary);
  text-transform: uppercase;
  height: 25px;
  padding-bottom: 5px;

}

.category-button:first-child {
  margin-left: 0;
}

div.order-categories-inner > ion-button:first-child {
  margin-left: 25px;
}

.web div.order-categories-inner > ion-button:first-child {
  margin-left: 0px;
}

.category-button.active {
  border-bottom: 2px solid var(--ion-color-secondary);
  font-weight: bold;
}

#main > ion-content.route-order .list-ios  ion-item{
  /* background:var(--ion-color-primary); */
  margin-bottom: 10px;
  --border-color:var(--ion-color-light);
}

.order-list-items {
  flex: 0 1 100%;
  overflow-y: auto;
  /* height: 100%; */
  /* padding-bottom: 70px; */
}

.order-list-items > .list-md {
  padding-bottom: 0;
}

.order-list-items ion-item {
  --min-height: 20px;
  --inner-padding-end: 0;
}

.order-list-item {
  display: flex;
  align-items: center;
  width: 100%;
}
.order-list-item > div {
  flex: 0 1 auto;
}

.order-list-item > div:nth-child(1) {
  flex: 1 1 auto;
}
.order-list-item > div:nth-child(2) {
  align-self: flex-start;
}
.item-content {
  font-size: 0.8em;
}
.item-content > h5 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  color: var(--ion-color-dark);
  letter-spacing: 0.044em;
}

.item-content > div {
  color: var(--ion-color-primary);
  font-size: var(--okx-small-text-font-size);
  margin-top: 5px;
}

.item-content p {
  color: var(--ion-color-primary);
  margin: 0;
  margin-top: 5px;
}
.item-image {
  border-radius: 5px;
  background: none transparent no-repeat 50% 50%;
  background-size: 100% auto;
  width: 50px;
  padding-top: 100%;
}
.item-price > span:first-child {
  color: var(--ion-color-secondary);
  /* color: var(--ion-color-primary); */
  font-size: 13px;
  font-weight: bold;
}
.item-price > span.original-price {
  text-decoration: line-through;
  font-size: 0.9em;
}
/*
.order-list-item:last-of-type {
	margin-bottom: 0;
}

.order-list-item > div:first-of-type {
	flex: 1;
	display: flex;
	align-items: center;
}

.order-list-item > div:first-of-type > img {
	border-radius: 10px;
	margin-right: 10px;
}

.order-list-item > div:first-of-type > div {
	display: flex;
	flex-flow: column;
}

.order-list-item > div:first-of-type > div > p {
	color: var(--ion-color-medium);
	font-size: 11px;
	margin:0;
}

.order-list-item > div:first-of-type > div > ion-label {
	font-size: 16px;
}

.order-list-item > p:last-of-type {
	color: var(--ion-color-workshop);
}
*/
.order-list-header {
  padding: 25px;
  margin-bottom: 10px;
}

.order-list-header ion-label {
  font-size: 25px;
  font-weight: 600;
  text-transform: initial;
}

.order-sublist-header {
  padding-left: 0;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  background-color: var(--okx-background-color);
  z-index: 2;
  --color: var(--ion-color-primary);
}

.order-sublist-header.empty {
  display: none;
}

.order-sublist-header ion-label {
  font-size: 16px;
  font-family: primary-bold;
  color: var(--ion-color-primary);
  font-weight: bold;

}

.order-intro-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: var(--ion-color-light-shade);
}

.order-intro-image-wrapper {
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-black);
  background: var(--ion-color-light-shade);
  width: 100%;
}

.order-intro-content {
  height: 250px;
  padding: 25px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  background: var(--ion-color-medium-contrast);
}

.order-intro-content p {
  color: var(--ion-color-black);
  text-align: center;
  padding: 0 10px;
  font-size: var(--ion-font-size);
  margin: 25px 0;
}

.route-order > .no-padding > .segment-holder > .order-categories > ion-text > span {
  width: calc(100vw - 40px);
  box-sizing: inherit;
  padding: 0 25px 20px 25px;
  float: left;
}
.order-sublist-header {
  background-color: var(--okx-background-color);

}
.route-order>.no-padding{
  background-color: var(--okx-background-color);
}
.route-order > .no-padding > .segment-holder > .order-categories > ion-text > span > .single-item {
  margin-bottom: 5px;
}

.order-intro-modal > .modal-wrapper {
  height: 320px;
  width: 270px;
  border-radius: 15px;
}

.order-intro-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  padding: 15px 25px;
}

.order-intro-modal > .modal-wrapper > .ion-page ion-icon {
  position: absolute;
  right: 15px;
  font-size: 35px;
}

.order-intro-modal > .modal-wrapper > .ion-page h2 {
  font-size: 20px;
  margin-top: 4px;
}

.order-intro-modal > .modal-wrapper > .ion-page .order-intro-modal-inner {
  flex: 1 1 0%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.location-select {
  --padding-start: 0;
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.popover-content ion-item ion-label {
  margin-left: 10px;
}

.pickup-time {
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.item-content {
  margin-right: auto;
}

.item-image {
  margin-left: auto;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
}
.app {
  display: grid;
  grid-template-columns: 20% 80%;
}
.navbar {
  padding: 0.5rem;
}
.navbar .nav-link {
  padding: 0.5rem;
}
.header {
  height: 80px;
  overflow: auto;
  background: #aaa;
}
.container {
  height: 500px;
  background: #ddd;
}
.gap {
  height: 500px;
}
.gap.short {
  height: 250px;
}
.gap.tall {
  height: 1000px;
}
.container.relative {
  overflow-y: auto;
}

.view-order-button {
  position: fixed;
  bottom: 0px;
  font-family: var(--ion-font-family);
  text-align: center;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.view-order-button ion-button {
  margin: 0;
}

.small-text .order-header  strong{
  font-size: 12px;
}
 .order-header  {
  margin: 0 0 5px 0;
}
.order-header-content-wrapper{
  padding: 0 var(--ion-padding) 10px;
}

.item-price, .item-name{
  color: var(--ion-color-primary) !important ;
}

@media (max-width: 700px) {
  .app {
    grid-template-columns: unset;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  .order-list-items {
    height: 100vh;
  }
}

.search-box {
  position: relative;
}
.search-box ion-icon {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 1px;
  left: 10px;
  fill: var(--ion-color-gray);
  z-index: 6;
}
.search-box ion-input {
  border: 1px solid var(--ion-color-gray);
  --padding-start: 30px;
  border-radius: 7px;
}
.search-box ion-icon.reset-icon{
  left: unset;
  right: 10px;
  cursor: pointer;
}
.no-searched-products-message {
  text-transform: uppercase;
  margin-left: 25px;
  font-size: var(--okx-title-font-size);
}

.search-modal-wrapper .modal-wrapper {
  height: 100%;
  width: 100%;
  border-radius: 0;
}
.search-modal-wrapper .modal-classic-closer ion-icon{
  height: 20px;
  width: 20px;
}
.search-items-icon{
 display: flex;
 justify-content: space-between;
 margin-top: 10px;
 align-items: center;
 
}
.search-items-icon ion-icon{
  height: 25px;
  width: 25px;
}

.search-items-icon ion-icon.active{
  color: var(--ion-color-secondary);
}