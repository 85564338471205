.apple-pay-button {
  display: inline-block;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  padding: 0px;
  box-sizing: border-box;
  width: 100vh;
  height: 60%;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: check-out;
}

.apple-pay-button-black {
  -apple-pay-button-style: black;
}

.apple-pay-button-white {
  -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}

.apple-button {
  --background-hover-opacity: 0;
}

.google-button {
  background-image: url(../../assets/images/gpay.png);
  --background: transparent none;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  border-radius: 5px;
  background-size: cover;
}
