.tabs {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  z-index: 0;
}

.tabs .segment-holder {
  position: sticky;
  position: -webkit-sticky;
  flex: 0 0 auto;
  top: 0;
  z-index: 2;
}

.tabs ion-segment-button {
  --indicator-color: transparent;
  font-size: 12px;
  background-color: transparent;
  color: var(--ion-color-primary);
  border-bottom: 0;
  --indicator-color:var(--ion-color-secondary)
}

.tabs ion-segment-button > ion-label {
  text-transform: none;
  font-weight: bold;
  color: var(--ion-color-primary);

}

.tabs ion-slides,
ion-slide {
  flex: 1 1 auto;
  margin: 0;
  height: calc(100vh - 163px);
}

.loyalty-bar > div {
  flex: 1 1 auto;
  text-align: left;
}

.loyalty-bar > div:first-child {
  flex: 0 0 20px;
  margin-left: 24px;
}

.loyalty-bar > div:nth-of-type(2) > ion-label {
  font-weight: bold;
}

.loyalty-bar > div:last-child {
  text-align: right;
}

.loyalty-bar .loyalty-badge {
  transform: scale(0.6);
  margin: 0 0 0 24px;
}

.loyalty-bar .loyalty-balance {
  margin: 0 24px 0 0;
  font-weight: bold;
  font-size: var(--ion-font-size);
}
.loyalty-bar .subtitle{
  font-size: var(--ion-font-size);
}
.loyalty-bar img{
  height: 15px;
}
.loyalty-badge {
  width: var(--okx-loyalty-badge-width);
  position: relative;
  border-radius: 50%;
  padding: var(--okx-loyalty-badge-inner-padding);
  background-color: #fcba29;
  border: 2px solid #c58d13;
  margin: 20px auto;
}

.loyalty-badge > div {
  padding-top: calc(100% - 4px);
  border-radius: 50%;
  border: inherit;
}

.loyalty-badge > div::before,
.loyalty-badge > div::after {
  content: '';
  display: block;
  position: absolute;
  right: 6px;
  bottom: 8px;
  background-color: #fcba29;
  width: 2px;
  height: 5px;
  transform: rotate(-40deg);
}

.loyalty-badge > div::after {
  right: 10px;
  bottom: 6px;
}

.loyalty-badge > div ion-icon {
  --lb-icon-offset: calc(
    var(--okx-loyalty-badge-width) / 2 - var(--okx-loyalty-badge-inner-padding)
  );
  position: absolute;
  left: var(--lb-icon-offset);
  top: var(--lb-icon-offset);
  font-size: 20px;
  margin: -9px 0 0 -8px;
  color: #c58d13;
}

.loyalty-bar {
  position: absolute;
  width: 100vw;
  height: var(--okx-loyalty-bar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  background-color: var(--ion-color-white);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  left: 0;
  bottom: 0;
  z-index: 2;
}

.loyalty-bar ion-label {
  color: white !important;
}

.loyalty-balance-label {
  padding-left: 20px;
  font-weight: bold;
}

.web .loyalty-bar {
  position: static;
  width: auto;
}

.slider-wrap,.slider-wrap > div{
  height: 100%;
}