.menu-enabled .list-md {
  padding-top: 0;
  padding-bottom: 0;
}

.drawer-menu .title-default {
  text-transform: uppercase;
  font-weight: bold;
  padding-inline-start: 0;
}

.drawer-menu ion-header,
.drawer-menu ion-content,
.drawer-menu ion-footer {
  --padding-start: var(--okx-drawer-padding);
  --padding-end: var(--okx-drawer-padding);
  padding-left: var(--okx-drawer-padding);
  padding-right: var(--okx-drawer-padding);
}

.drawer-menu ion-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawer-menu ion-footer {
  padding-top: 5px;
  padding-bottom: 20px;
}

.drawer-menu ion-footer::before {
  background-image: none;
}

.drawer-menu .nav-logo {
  width: 100%;
  height: 68px;
  background: url(../../assets/images/logo-color.png) transparent no-repeat 0% 50% / auto 25%;
}

.drawer-menu ion-content {
  --ion-color-base: transparent !important;
  --background: transparent;
}

.drawer-menu .nav-label {
  font-size: var(--drawer-font-size);
  text-transform: capitalize;
}
.drawer-menu .terms-items .nav-label {
  color: rgba(var(--ion-color-base), 0.6) !important;
  font-weight: normal;
}

.drawer-menu .nav-item {
  --ion-color-base: transparent !important;
  --padding-start: 0;
  --min-height: 24px;
  font-weight: bold;
}
.drawer-menu .nav-item .item-detail-icon {
  --oppacity:0
}
ion-list.terms-items.ios.list-ios.list-lines-none.list-ios-lines-none.hydrated
  > ion-menu-toggle
  > ion-item
  > ion-label,
ion-list.terms-items.md.list-md.list-lines-none.list-md-lines-none.hydrated
  > ion-menu-toggle
  > ion-item
  > ion-label {
  color: rgba(var(--ion-color-white-rgb), 0.6);
}

ion-list.terms-items .nav-item > .nav-icon {
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 0;
}

.drawer-menu .nav-item.small-text {
  font-weight: normal;
}
.drawer-menu .nav-icon{
  height: 25px;
  width: 25px;
  stroke-width: 2px;
  margin: 5px;
}
.drawer-menu ion-header ion-toolbar:last-child {
  --border-width: 0 0 0;
  --ion-color-base: transparent !important;
}

.drawer-menu ion-icon {
  
  /* fill: #fff; */
}

.logo-5l {
  position: absolute;
  right: 16px;
  bottom: 8px;
}

.logo-5l > div {
  width: 100px;
  padding-top: 45%;
  background: url(../../assets/images/icons/5loyalty_logo.svg) transparent no-repeat 50% 50%;
  background-size: 70% auto;
  opacity: 0.4;
}


ion-split-pane {
  --side-max-width: var(--okx-drawer-width);
  --side-min-width: var(--okx-drawer-width);
  --side-width: var(--okx-drawer-width);
  --border: none;
}
ion-menu {
  --background: var(--okx-background-color)
}
/* 

ion-menu ion-content::after,
ion-menu ion-content::before,
.web ion-menu::before,
.web ion-menu::after {
  position: absolute;
  content: '';
  height: 100px;
  width: 100px;
  background-image: url(../../assets/images/membership-flower.png);
  z-index: 2;
  background-size: cover;
  background-repeat: no-repeat;
}
ion-menu ion-content::after {
  top: -40px;
  right: -50px;
}
.web ion-menu::after{
  top: 45px;
  right: -50px;
}
ion-menu ion-content::before,.web ion-menu::before {
  bottom: 10px;
  left: -50px;
} */

.web ion-menu ion-content::after, .web ion-menu ion-content::before{
  display: none;
}

.web ion-menu {
  --max-width: var(--okx-drawer-width);
}

ion-footer > ion-label[hidden] {
  display: block !important;
}

.collapse-drawer-icon,
ion-content.log-status {
  display: none;
}

/* .web ion-footer > ion-label[hidden] {
	display: none !important;
} */

.web .drawer-menu ion-header ion-toolbar {
  position: relative;
}

.web .drawer-menu .nav-logo {
  width: 90%;
  cursor: pointer;
  background-position: 0% 50%;
  fill: #fff;
}

.web .drawer-menu .nav-icon {
  font-size: 20px;
}

.web .small-drawer-header {
  padding: 15px 0 0 0;
}

.web .small-drawer-toolbar {
  --background: transparent;
}

ion-toolbar {
  --border-color: transparent !important;
}

.web .small-drawer-header ion-buttons {
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.web .small-drawer-header ion-button {
  --padding-top: 12px;
  --padding-end: 12px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 48px;
  height: 48px;
  background: var(--ion-color-white);
}

.web .small-drawer-header ion-button ion-icon {
  font-size: 1.8rem;
  color: var(--okx-color-black);
}

.web .collapse-drawer-icon {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
  cursor: pointer;
  font-size: 25px;
}

.web ion-content.log-status {
  display: block;
  max-height: 115px;
}

.web .log-status-wrap {
  border-radius: var(--okx-box-radius);
  border: 1px solid var(--ion-color-gray);
  overflow: hidden;

}

.web .log-status-wrap ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
}

.web .log-status-wrap ion-item:first-of-type {
  --background: var(--okx-background-color);
  --border-radius: var(--okx-box-radius);
  backface-visibility: hidden;
  z-index: 2;
}

.web .log-status-wrap ion-item:last-of-type {
  border-bottom-left-radius: var(--okx-box-radius);
  border-bottom-right-radius: var(--okx-box-radius);
  --min-height: 35px;
}

.web .log-status-wrap ion-item.logout-box {
  border-top: 1px solid var(--ion-color-gray);
}

.web .log-status-content {
  width: 100%;
  background-color: var(--okx-background-color);
}

.web .log-status-content > ion-text .subtitle {
  margin: 0;
  font-size: var(--ion-font-size);
  font-weight: 500;
  color: var(--ion-color-primary);
}

.web .log-status-content > ion-text .small-text {
  color: var(--ion-color-primary);
  opacity: 0.6;
  display: block;
  margin-top: -2px;
}

.web .log-status-wrap ion-icon {
  color: var(--ion-color-primary);
}

.web .logout-box > ion-label,
.web .logout-box > ion-label > ion-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.web .logout-box > ion-label > ion-icon {
  font-size: 17px;
  margin-right: 5px;
}

.web .logout-box > ion-label > ion-text .normal-text {
  font-size: 15px;
  margin-left: 5px;
}

.web .logout-box {
  margin-top: -40px;
  transition: all 0.3s ease;
}

.web .logout-box.visible {
  margin-top: 0;
}

.web .drawer-menu ion-content:first-of-type {
  --padding-start: 0px;
}

.web .drawer-menu ion-content:first-of-type ion-item {
  --padding-start: var(--okx-drawer-padding);
}

.web .drawer-menu ion-content:first-of-type ion-item.okx-nav-selected > ion-icon {
  background: var(--ion-color-secondary);
  border-radius: 50%;
  fill: var(--ion-color-primary-contrast);
}

.drawer-menu .nav-icon-gift {
  stroke:var(--ion-color-secondary) !important;
}

.web .drawer-menu ion-content:first-of-type ion-item.okx-nav-selected > .nav-icon-gift {
  background: var(--ion-color-secondary);
  border-radius: 50%;
  stroke:var(--ion-color-primary-contrast) !important;
}


.web .drawer-menu .nav-item {
  --min-height: 33px;
  cursor: pointer;
}

.web .drawer-menu .nav-icon,
.drawer-menu .nav-label {
  margin-top: 0;
  margin-bottom: 0;

}

.web .drawer-menu ion-header {
  height: 90px;
}

.web .drawer-menu .nav-item.small-text > .nav-icon,
.drawer-menu .nav-item.small-text > .nav-label {
  margin-top: 0;
  margin-bottom: 0;
}

.web .drawer-menu .nav-item.small-text > .nav-icon{
  display:none;
}
.drawer-menu .nav-item.small-text {
  --min-height: 26px;
  margin-left: 10px;
}

.web .drawer-menu ion-content:first-of-type ion-item.small-text.okx-nav-selected > ion-icon {
  background-color: transparent;
}

@media screen and (min-width: 992px) {
  .web .collapse-drawer-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

@media screen and (min-width: 768px) {
  .web .collapse-drawer-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

@media screen and (min-width: 1024px) {
  .web .collapse-drawer-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

.header-md::after {
  background-image: none;
}
